<template>
  <div>
    <div v-if="this.data.length > 0" class="container okoscsomag">
      <h1 class="col-12 text-center my-5">{{ $t('modules.smart')}}</h1>
      <div class="container px-0">
        <div v-for="(items, key) in chunkedItems(this.data)" :key="key" class="row">
          <div v-for="(item, index) in (items)" :key="index" class="col-lg-6">
          <ProductList :data="item" :index="index"/>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProductList from "@/components/partials/ProductList";
import _ from "lodash";

export default {
  name: "module14",
  components: {ProductList},
  props: {
    data: {data: [Array]},
  },
  methods: {
    chunkedItems(items) {
      return _.chunk(items.slice(0), 2);
    }
  }
}
</script>

<style scoped>

</style>
